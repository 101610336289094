import React from 'react';
import {isMobile} from 'react-device-detect';
import {paramsToObject} from '../utils/functionUtils';

import {
  CtaBox,
  Faq,
  Footer,
  HeroSection,
  ImgStats,
  SplitWithImg,
  StatsBox,
  StepsBox,
  TopMenu,
  SplitSection,
  ComparisonTable,
  LogoSection,
  Metatags,
  MediaSection,
} from '../sections';
import {PaymentsCalculator} from '../molecules';

// eslint-disable-next-line react/prop-types
const IndexPage = ({location}) => {
  const queryString = typeof window !== 'undefined' && window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.entries();
  const queryParams = paramsToObject(entries);
  const appendQueryString = queryString.toString();

  const gotoForm = () =>
    window.scrollTo({
      top: isMobile ? 3900 : 2860,
      behavior: 'smooth',
    });

  const pageId = 'GC10X';
  const justBiz = true;

  const navToNewApp = false;

  return (
    <main className="bg-white-100">
      <Metatags />
      <TopMenu
        queryParams={queryParams}
        appendQueryString={appendQueryString}
        navToNewApp={navToNewApp}
      />
      <HeroSection
        rightComponent={
          <PaymentsCalculator gotoForm={() => gotoForm()} isDark={false} />
        }
      />
      <MediaSection />
      <ImgStats />
      <StatsBox />
      <StepsBox />
      <ComparisonTable />
      <SplitWithImg />
      <SplitSection
        queryParams={queryParams}
        appendQueryString={appendQueryString}
        pageId={pageId}
        justBiz={justBiz}
        location={location}
        navToNewApp={navToNewApp}
      />
      <Faq />
      <LogoSection />
      <CtaBox appendQueryString={appendQueryString} navToNewApp={navToNewApp} />
      <Footer navToNewApp={navToNewApp} />
    </main>
  );
};

export default IndexPage;
